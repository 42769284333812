.logo {
  margin: 24px 0 0 24px;
  position: relative;
}

.logoLink {
  height: 64px;
  display: inline-block;
}

@media screen and (max-width: 1200px) {
  .logo {
    margin: 24px auto 0 auto;
  }
}