.navigationContainer {
  display: flex;
  width: 100%;
  margin-top: auto;
  justify-content: flex-end;
}

.navigationWrapper {
  display: flex;
  flex-direction: column;
  margin: 24px 48px;
}

.leftRightArrow {
  display: flex;
}

.arrowBtn:last-child{
  margin-left: 32px;
}

.arrowBtn {
  padding: 0;
  border: none;
  display: flex;
  border-radius: 4px;
  background-color: black;
  cursor: pointer;
  outline: none;
}

.arrowBtn:active{
  filter: brightness(75%);
}

@media screen and (max-width: 610px) {
  .navigationWrapper {
    margin: 24px;
  }  
}