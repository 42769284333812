#root{
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.body {
  flex: 1;
  display: flex;  
  background-image: url("./assests/images/bg_img.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  min-width: 80%;
  margin: auto;
}

@media screen and (max-width: 1200px) {
  .body {
    flex-direction: column;
    margin: 0;
  }
}
