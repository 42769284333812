.main {
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  align-items: center;
  margin-left: -133px;
}

.title {
  padding-top: 48px;
  font-family: 'Merriweather', Georgia, serif; 
  color: #FBB425;
  font-weight: 600;
  letter-spacing: 1.2px;
  font-size: 36px;
}

@media screen and (max-width: 1200px) {
  .main {
    margin-left: 0;
  }
}
