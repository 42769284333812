.actionButton {
  color: #fff;
  background-color: #FBB425;
  border: none;
  border-radius: 4px;
  padding: 8px 20px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  outline: none;
}

.actionButton:active{
  filter: brightness(85%);
}