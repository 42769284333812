.playerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.playerWrapper {
  min-width: 860px;
  min-height: 460px;
}

.videoTitle {
  font-family: 'Merriweather', Georgia, serif;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.44px;
  color: #fff;
  font-weight: 400;
  margin: 0;
  padding: 16px 0 24px 0;
}

.actionsWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.btnsWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mobileSwitch {
  display: none;
}

.tabletSwitch {
  display: block;
}

@media screen and (max-width: 860px) {
  .playerWrapper {
    min-width: 100%;
    min-height: 100%;
  }
}

@media screen and (max-width: 610px) {
  .btnsWrapper {
    flex-direction: row;
    align-items: flex-end;
  }

  .mobileSwitch {
    display: block;
  }
  
  .tabletSwitch {
    display: none;
  }
}