.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.container{
  width: 80%;
  display: flex;
  justify-content: center;
}

.leftWrapper {
  display: flex;
  flex-direction: column;
  margin: 48px;
}

.rightWrapper {
  display: flex;
  flex-direction: row;
  margin: 48px;
  margin-left: auto;
}

.linkWrapper {
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.linkWrapper .regularText {
  padding: 0 12px;
  text-decoration: none;
}

.regularText {
  color: #003A74;
  margin: 0;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.43;
}

.copyRight {
  color: grey;
  margin-top: 12px;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.43;  
}

@media screen and (max-width: 610px) {
  .container{
    flex-direction: column;
    width: 100%;
  }

  .leftWrapper {
    margin-bottom: 24px;
  }
  .rightWrapper {
    display: flex;
    flex-direction: row;
    margin-left: 24px;
    margin-top: 0;
  }
}