.zoomContainer {
  width: 32px;
  margin-left: auto;
  margin-right: 48px;
  background-color: black;
}

.zoomBtn {
  padding: 0;
  border: none;
  display: flex;
  border-radius: 4px;
  background-color: black;
  cursor: pointer;
  outline: none;
}

.zoomBtn:active{
  filter: brightness(75%);
}


@media screen and (max-width: 610px) {
  .zoomContainer {
    margin: 24px 48px;
    display: flex;
    flex-direction: row-reverse;
  }
}